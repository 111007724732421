import { SWRConfiguration } from 'swr'

import {
  getPromoBannerSpotElected,
  IGetPromoBannerSpotElectedArgs,
  IGetPromoBannerSpotElectedResponse,
} from '@/services/api-rest'
import { HTTPError } from '@/utils/api-helpers'

import { useSWRAuth } from './useSwr-auth'
import { useSWRCustom } from './useSwr-custom'

export const SWR_KEY__PROMO_BANNER_SPOT_ELECTED_RESULTS = 'promoBannerSpotElected_results'

interface IReturnType {
  promoBannerSpotElectedLoading: boolean
  promoBannerSpotElected: IGetPromoBannerSpotElectedResponse
  promoBannerSpotElectedError: HTTPError
}

export const useSWRPromoBannerSpotElectedResults = (
  { spot, areaKey }: IGetPromoBannerSpotElectedArgs,
  swrConfig: SWRConfiguration = { revalidateOnFocus: false, shouldRetryOnError: false }
): IReturnType => {
  const { payload } = useSWRAuth()
  const { data, error, isLoading } = useSWRCustom<IGetPromoBannerSpotElectedResponse>(
    [SWR_KEY__PROMO_BANNER_SPOT_ELECTED_RESULTS, payload?.uuid],
    getPromoBannerSpotElected,
    {
      spot,
      areaKey,
    },
    swrConfig
  )

  return {
    promoBannerSpotElectedLoading: isLoading,
    promoBannerSpotElected: data,
    promoBannerSpotElectedError: error,
  }
}
