import { IGetPromoBannerSpotElectedArgs } from '@/services/api-rest'
import { IPromoBanner } from '@/types/main'
import { HTTPError } from '@/utils/api-helpers'

import { useSWRPromoBannerSpotElectedResults } from './swr/useSwr-promoBannerSpotElectedResults'

export type IUsePromoBannerArgs = IGetPromoBannerSpotElectedArgs & {
  slot: number
}
interface IUsePromoBannerReturnType {
  promoBannerLoading: boolean
  promoBanner: IPromoBanner
  promoBannerError: HTTPError
}

export const usePromoBannerResult = ({
  spot,
  slot,
  areaKey,
}: IUsePromoBannerArgs): IUsePromoBannerReturnType => {
  const {
    promoBannerSpotElectedLoading,
    promoBannerSpotElected,
    promoBannerSpotElectedError,
  } = useSWRPromoBannerSpotElectedResults({ spot, areaKey })

  return {
    promoBannerLoading: promoBannerSpotElectedLoading,
    promoBanner: promoBannerSpotElected?.[`${spot}#${slot}`] ?? null,
    promoBannerError: promoBannerSpotElectedError,
  }
}
