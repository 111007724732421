import React from 'react'
import { theme } from 'theme'

import { ISvgProps } from '@/types/main'

export const CheckedIcon: React.FC<ISvgProps> = ({ className }) => (
  <svg
    width="50"
    height="34"
    viewBox="0 0 50 34"
    fill={theme.color.black}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M19.7352 33.1326C19.4391 33.1343 19.1455 33.0776 18.8714 32.9656C18.5972 32.8536 18.3479 32.6886 18.1377 32.4801L0.902673 15.2451C0.47899 14.8214 0.240967 14.2468 0.240967 13.6476C0.240967 13.0484 0.47899 12.4738 0.902673 12.0501C1.32636 11.6264 1.90099 11.3884 2.50017 11.3884C3.09935 11.3884 3.67399 11.6264 4.09767 12.0501L18.2502 26.1126C18.4593 26.3235 18.7082 26.4909 18.9824 26.6051C19.2566 26.7193 19.5506 26.7782 19.8477 26.7782C20.1447 26.7782 20.4388 26.7193 20.713 26.6051C20.9872 26.4909 21.236 26.3235 21.4452 26.1126L45.9027 1.5201C46.3264 1.09642 46.901 0.858398 47.5002 0.858398C48.0993 0.858398 48.674 1.09642 49.0977 1.5201C49.5214 1.94379 49.7594 2.51843 49.7594 3.1176C49.7594 3.71678 49.5214 4.29142 49.0977 4.7151L21.3327 32.4801C21.1224 32.6886 20.8731 32.8536 20.599 32.9656C20.3248 33.0776 20.0313 33.1343 19.7352 33.1326Z" />
  </svg>
)
