import { sendGTMEvent as sendEvent } from '@next/third-parties/google'
import { createHash } from 'crypto'

import { IUserProfileFormValues } from '@/components/account/profile/ProfileForm'
import {
  ILessonDecorated,
  IPrice,
  IPriceDecorated,
  IPromoBanner,
  IQuote,
  ISerieDecorated,
  IVideo,
  IVideoGroup,
} from '@/types/main'
import { formatDate } from '@/utils/date'

import { JWTPayload } from './auth'

type IGTMItems = {
  item_id: string
  item_name: string
  item_brand: 'olybe' | 'olybe school' | 'olybe b2b' | string
  price: number
  item_category: GTM_CATEGORY
  item_category2: 'subscription' | 'card'
  promotion_name?: string | undefined
}

const sendGTMEvent = (data: object) => {
  if (typeof window === 'undefined') {
    return
  }

  console.debug('GTM Event', data)
  sendEvent(data)
}

export enum GTM_CATEGORY {
  CARD = 'card',
  SUBSCRIPTION = 'subscription',
  PREPAID = 'prepaid',
  ONLINE = 'online',
  REPLAY = 'replay',
  STUDIO = 'studio',
  VIDEO = 'video',
  VIDEOGROUP = 'playlist',
}

const computeContentName = (lesson: ILessonDecorated): string => {
  const date = formatDate('PPPPp', lesson.startAt)

  return `${lesson.isOnline() ? 'ONLINE' : 'STUDIO'} - ${lesson.title} - ${date}`
}

const buildGTMItems = ({
  price,
  quote,
  banner,
}: {
  price: IPriceDecorated
  quote?: IQuote
  banner?: IPromoBanner
}): IGTMItems[] => {
  const item: IGTMItems = {
    item_id: price.ulid,
    item_name: price.name,
    item_brand: `olybe ${price.business !== 'b2c' ? price.business : ''}`.trim(),
    price: computePurchaseGTMArgs(price, quote).value,
    item_category: getPriceGTMCategory(price),
    item_category2: price.recurringSubscription ? 'subscription' : 'card',
    ...(banner && {
      promotion_name:
        `PromoBanner ${banner.uuid.split('-')[0]} ${price.eligibilityCondition ?? ''}`.trim(),
    }),
  }

  return [item]
}

const getPriceGTMCategory = (price: IPriceDecorated) => {
  if (price.prepaid) {
    return GTM_CATEGORY.PREPAID
  }

  return price.subscription ? GTM_CATEGORY.ONLINE : GTM_CATEGORY.STUDIO
}

export const signUpGTMEvent = (userUuid: string): void => {
  sendGTMEvent({
    event: 'screenInteraction',
    checkout_category: 'tunnel_sign',
    event_action: 'signup',
    event_label: 'Inscription',
  })
  sendGTMEvent({
    event: 'signup',
    userId: userUuid,
  })
}

export const loginGTMEvent = (userUuid: string, userEmail: string): void => {
  sendGTMEvent({
    event: 'screenChange',
    checkout_category: 'tunnel_sign',
    event_action: 'signin',
    event_label: 'Connexion',
  })
  sendGTMEvent({
    event: 'login',
    userId: userUuid,
    userEmail,
    userEmailSha: sha256(userEmail),
  })
}

export const oauthSignupGTMEvent = (userUuid: string): void => {
  sendGTMEvent({
    event: 'oauth_signup',
    userId: userUuid,
  })
}

export const oauthLoginGTMEvent = (userUuid: string): void => {
  sendGTMEvent({
    event: 'oauth_login',
    userId: userUuid,
  })
}

export const logoutGTMEvent = (): void => {
  sendGTMEvent({
    event: 'logout',
  })
}

export const updateUserProfileGTMEvent = (
  values: IUserProfileFormValues,
  userUuid: string
): void => {
  sendGTMEvent({
    event: 'edit_user',
    userId: userUuid,
    values: values,
  })
}

export const findStudioGTMEvent = (area: string): void => {
  sendGTMEvent({
    event: 'screenInteraction',
    checkout_category: 'tunnel_studio',
    event_action: 'Trouvez_votre_cours',
    event_label: 'studio',
    area: area,
  })
}

export const findLiveGTMEvent = (): void => {
  sendGTMEvent({
    event: 'screenInteraction',
    checkout_category: 'tunnel_live',
    event_action: 'Trouvez_votre_cours',
    event_label: 'live',
  })
}

interface IProductDetailViewGTMEventArgs {
  lesson?: ILessonDecorated
  videoGroup?: IVideoGroup
  video?: IVideo
}

export const productDetailViewGTMEvent = ({
  video,
  videoGroup,
}: IProductDetailViewGTMEventArgs = {}): void => {
  sendGTMEvent({
    event: 'productDetailView',
    ecommerce: {
      detail: {
        products: [
          ...(video
            ? [
                {
                  name: `VIDEO - ${video.title}`,
                  uuid: video.ulid,
                  id: video.ulid,
                  category: GTM_CATEGORY.VIDEO,
                  brand: 'olybe',
                },
              ]
            : []),
          ...(videoGroup
            ? [
                {
                  name: `PLAYLIST - ${videoGroup.title}`,
                  uuid: videoGroup.ulid,
                  id: videoGroup.ulid,
                  category: GTM_CATEGORY.VIDEOGROUP,
                  brand: 'olybe',
                },
              ]
            : []),
        ],
      },
    },
  })
}

export const viewItemGTMEvent = (price: IPriceDecorated): void => {
  sendGTMEvent({
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      ...computePurchaseGTMArgs(price),
      items: buildGTMItems({ price }),
    },
  })
}

export const viewBannerGTMEvent = (banner: IPromoBanner): void => {
  sendGTMEvent({
    event: 'view_banner',
    banner_name: `Banner ${banner.uuid.split('-')[0]}`,
    banner_id: banner.uuid,
  })
}

export const viewPromotionGTMEvent = (
  banner: IPromoBanner,
  price: IPriceDecorated | Partial<IPrice> | null,
  creativeSlot?: string
): void => {
  sendGTMEvent({
    event: 'view_promotion',
    ecommerce: {
      promotion_id: banner.uuid,
      creative_slot: creativeSlot,
      items: price ? buildGTMItems({ price: price as IPriceDecorated, banner }) : [],
    },
  })
}

export const selectPromotionGTMEvent = (
  banner: IPromoBanner,
  price: IPriceDecorated | Partial<IPrice> | null,
  creativeSlot?: string
): void => {
  sendGTMEvent({
    event: 'select_promotion',
    ecommerce: {
      promotion_id: banner.uuid,
      creative_slot: creativeSlot,
      items: price ? buildGTMItems({ price: price as IPriceDecorated, banner }) : [],
    },
  })
}

const baseContentGTMEvent = ({
  event,
  lesson,
  serie,
}: {
  event: string
  lesson?: ILessonDecorated
  serie?: ISerieDecorated
}): void => {
  if (lesson) {
    sendGTMEvent({
      event,
      ...formatLessonAsContent(lesson),
    })
  }

  if (serie) {
    sendGTMEvent({
      event,
      ...formatSerieAsContent(serie),
    })
  }
}

type SerieAsContent = {
  content_name: string
  content_id: string
  content_category: string // online / studio
  content_category2: string // area
  content_category3: string | null // teacher name
  content_category4: string | null // place title
}

type LessonAsContent = {
  content_name: string
  content_id: string
  content_category: string // online / studio
  content_category2: string // area
  content_category3: string | null // teacher name
  content_category4: string | null // place title
  content_category5: string | null // serie uuid
}

const formatSerieAsContent = (serie: ISerieDecorated): SerieAsContent => ({
  content_name: `SERIE ${serie.isOnline() ? 'ONLINE' : 'STUDIO'} - ${serie.title}`,
  content_id: serie.ulid,
  content_category: serie.isOnline() ? GTM_CATEGORY.ONLINE : GTM_CATEGORY.STUDIO,
  content_category2: serie.area,
  content_category3: serie.teacher?.name,
  content_category4: serie.place?.title,
})

const formatLessonAsContent = (lesson: ILessonDecorated): LessonAsContent => ({
  content_name: computeContentName(lesson),
  content_id: lesson.uuid,
  content_category: lesson.isOnline() ? GTM_CATEGORY.ONLINE : GTM_CATEGORY.STUDIO,
  content_category2: lesson.area,
  content_category3: lesson.teacher?.name,
  content_category4: lesson.place?.title,
  content_category5: lesson.serie?.ulid,
})

export const viewContentGTMEvent = (args: {
  lesson?: ILessonDecorated
  serie?: ISerieDecorated
}): void => {
  baseContentGTMEvent({ event: 'view_content', ...args })
}

export const selectContentGTMEvent = (args: {
  lesson?: ILessonDecorated
  serie?: ISerieDecorated
}): void => {
  baseContentGTMEvent({ event: 'select_content', ...args })
}

export const addToCartGTMEvent = ({
  price,
  lesson = null,
}: {
  price: IPriceDecorated
  lesson?: ILessonDecorated
}): void => {
  const args = computePurchaseGTMArgs(price)
  sendGTMEvent({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: args.value,
      items: buildGTMItems({ price }),
    },
    ...(lesson && { lesson: formatLessonAsContent(lesson) }),
  })
}

export const checkoutGTMEvent = ({ price }: { price: IPriceDecorated }): void => {
  const args = computePurchaseGTMArgs(price)
  sendGTMEvent({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      price: args.value,
      items: buildGTMItems({ price }),
    },
  })
}

// deprecated
export const orderCompleteGTMEvent = (
  quote: IQuote,
  price: IPriceDecorated,
  payload: JWTPayload
): void => {
  sendGTMEvent({
    event: 'orderComplete',
    ecommerce: {
      purchase: {
        actionField: {
          id: quote.uuid,
          revenue: quote.total / 100,
          tax: quote.items.tax ? quote.items.tax.amount / 100 : 0,
          currency: quote.currency,
          coupon:
            quote.misc.voucher_code ??
            (quote.misc.subscription_trial ? 'trialing' : 'no_coupon'),
        },
      },
      products: [
        {
          name: price.name,
          id: price.ulid,
          price: price.totalAmount / 100,
          brand: 'olybe',
          category: getPriceGTMCategory(price),
          time: quote.createdAt,
          coupon: quote.misc.subscription_trial ? 'trialing' : '',
          subscription: price.recurringSubscription ?? false,
        },
      ],
    },
    user: {
      id: payload.uuid.split('-')[0],
      emailSha: sha256(payload.email),
    },
  })
}

interface IPurchaseInfoResult {
  value: number
  tax: number
  discount: number
}
const computePurchaseGTMArgs = (
  price: IPriceDecorated,
  quote?: IQuote
): IPurchaseInfoResult => {
  if (quote) {
    return {
      value: quote.total / 100 - (quote.items.tax ? quote.items.tax.amount / 100 : 0),
      tax: quote.items.tax ? quote.items.tax.amount / 100 : 0,
      discount:
        quote.items.discounts.reduce((total, item) => total + item.amount, 0) / 100,
    }
  }

  return {
    value: (price.totalAmount - price.taxAmount) / 100,
    tax: price.taxAmount / 100,
    discount: 0,
  }
}

export const purchaseGTMEvent = (quote: IQuote, price: IPriceDecorated): void => {
  sendGTMEvent({
    event: 'purchase',
    ecommerce: {
      transaction_id: quote.uuid,
      currency: quote.currency,
      ...computePurchaseGTMArgs(price, quote),
      coupon:
        quote.misc.voucher_code ??
        (quote.misc.subscription_trial ? 'trialing' : undefined),
      items: buildGTMItems({ price, quote }),
    },
  })
}

export const scheduleGTMEvent = (lesson: ILessonDecorated): void => {
  sendGTMEvent({
    event: 'schedule',
    ...formatLessonAsContent(lesson),
  })
}

export const videoViewGTMEvent = (video: IVideo): void => {
  sendGTMEvent({
    event: 'video_view',
    product_name: video.title,
    product_id: video.ulid,
    product_category: 'video',
  })
}

export const replayViewGTMEvent = (lesson: ILessonDecorated): void => {
  sendGTMEvent({
    event: 'videoView',
    payment_category: GTM_CATEGORY.SUBSCRIPTION,
    product_name: lesson.title,
    product_id: lesson.uuid,
    product_category: 'replay',
  })
}

export const downloadInvoiceGTMEvent = (): void => {
  sendGTMEvent({
    event: 'download_invoice',
  })
}

const sha256 = (content: string) => createHash('sha256').update(content).digest('hex')
