import React from 'react'
import { useInView } from 'react-intersection-observer'
import { FormattedNumber } from 'react-intl'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { Container } from '@/components/_layout/Container'
import {
  selectPromotionGTMEvent,
  viewBannerGTMEvent,
  viewPromotionGTMEvent,
} from '@/services/gtm'
import { IPromoBanner } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

import { CustomButtonLink } from '../_custom/CustomButton'
import { CheckedIcon } from '../_svg/icons/CheckedIcon'
import { NextImage } from './NextImage'

interface IProps {
  promoBanner: IPromoBanner
  creativeSlot?: string
}

export const PromoBanner: React.FC<IProps> = ({ promoBanner, creativeSlot }) => {
  const { ref } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        if (!promoBanner.price) {
          viewBannerGTMEvent(promoBanner)
          return
        }

        viewPromotionGTMEvent(promoBanner, promoBanner.price, creativeSlot)
      }
    },
  })

  if (promoBanner.facet === 'lightLesson') {
    return (
      <div ref={ref}>
        <PromoBannerLightLesson promoBanner={promoBanner} />
      </div>
    )
  }

  return (
    <div ref={ref}>
      <PromoBannerDefault promoBanner={promoBanner} />
    </div>
  )
}

const PromoBannerDefault: React.FC<IProps> = ({ promoBanner, creativeSlot }) => (
  <>
    <NextImage
      src={promoBanner.backgroundImagePath}
      alt={promoBanner.title}
      imgixPreconfig="promoCrop"
      sizes={`100vw`}
      fill
      objectFitCover
    />

    <CapsuleContainer $position={promoBanner.capsule.position}>
      <Capsule $color={promoBanner.capsule.color}>
        <Topic>{promoBanner.topic}</Topic>
        <Title>{promoBanner.title}</Title>

        <List>
          {promoBanner.bulletPoints.map((bulletPoint, i) => (
            <ListItem key={i}>{bulletPoint}</ListItem>
          ))}
        </List>

        {promoBanner.price && promoBanner.capsule.displayPrice && (
          <Price>
            <FormattedNumber
              value={promoBanner.price.totalAmount / 100}
              style="currency"
              currency="EUR"
              maximumFractionDigits={0}
            />
          </Price>
        )}

        <StyledButtonLink
          href={promoBanner.button.href}
          color={(theme.color[promoBanner.button.color] as string) ?? null}
          onClick={() => {
            if (!promoBanner.price) {
              return
            }

            selectPromotionGTMEvent(promoBanner, promoBanner.price, creativeSlot)
          }}
        >
          {promoBanner.button.label}
        </StyledButtonLink>
      </Capsule>
    </CapsuleContainer>
  </>
)

const CapsuleContainer = styled(Container)<{
  $position: IPromoBanner['capsule']['position']
}>`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${({ $position }) =>
      $position === 'left'
        ? css`
            display: grid;
            grid-template-columns: 1fr 1fr;
          `
        : css`
            margin-left: auto;
            margin-right: 0;
            min-width: fit-content;
            width: 50%;
          `}}
  }
`
const Capsule = styled.div<{ $color: string }>`
  align-items: center;
  background-color: ${({ $color }) => $color};
  border-radius: 10000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 335px;
  padding: ${({ theme }) => theme.spacing.xxxl} 0;
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    max-width: 400px;
  }
`
const Topic = styled.p`
  color: ${({ theme }) => theme.color.greyDark};
  letter-spacing: 0.1em;
  margin-bottom: ${({ theme }) => theme.spacing.ms};
  text-transform: uppercase;
`
const Title = styled.div`
  ${({ theme }) => theme.typo.h2};

  margin-bottom: ${({ theme }) => theme.spacing.s};
  padding: 0 ${({ theme }) => theme.spacing.xl};
  white-space: break-spaces;
`
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.s};
  padding: 0 ${({ theme }) => theme.spacing.ms};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing.ml};
  }
`
const Price = styled.p`
  ${({ theme }) => theme.typo.h3};

  margin: ${({ theme }) => theme.spacing.s} 0;
`
const StyledButtonLink = styled(CustomButtonLink)`
  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: ${({ theme }) => theme.spacing.m};
  }
`

const ListItem: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ListItemWrapper>
    <StyledCheckedIcon />
    {children}
  </ListItemWrapper>
)

const ListItemWrapper = styled.li`
  align-items: center;
  display: flex;
  text-align: left;
`
const StyledCheckedIcon = styled(CheckedIcon)`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  fill: ${({ theme }) => theme.color.terracota};
  height: ${({ theme }) => theme.spacing.ms};
  margin-right: ${({ theme }) => theme.spacing.s};
  padding: 6px;
  stroke: ${({ theme }) => theme.color.terracota};
  width: ${({ theme }) => theme.spacing.ms};
`

const PromoBannerLightLesson: React.FC<IProps> = ({ promoBanner, creativeSlot }) => (
  <StyledContainer $bgColor={theme.color[`${promoBanner.button.color}Light`] as string}>
    <TitleAlt>{promoBanner.title}</TitleAlt>

    <CustomButtonLink
      href={promoBanner.button.href}
      color={theme.color.white}
      onClick={() => {
        if (!promoBanner.price) {
          return
        }

        selectPromotionGTMEvent(promoBanner, promoBanner.price, creativeSlot)
      }}
    >
      {promoBanner.button.label}
    </CustomButtonLink>
  </StyledContainer>
)

const StyledContainer = styled(Container)<{ $bgColor: string }>`
  background-color: ${({ $bgColor }) => $bgColor};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ms};
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.ms};
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    align-items: center;
    gap: ${({ theme }) => theme.spacing.ml};
    padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.ms};
  }
`
const TitleAlt = styled.div`
  ${({ theme }) => theme.typo.h2};

  white-space: break-spaces;
`
